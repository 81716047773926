export default [
	{
		path: '/',
		name: 'home',
		meta: {},
		component: () => import('@/views/pages/home'),
	},
	{
		path: '/login',
		name: 'login',
		meta: {
			navbarStyle: 'two',
			requiresGuest: true,
		},
		component: () => import('@/views/pages/login'),
	},
	{
		path: '/register',
		name: 'register',
		meta: {
			navbarStyle: 'two',
			requiresGuest: true,
		},
		component: () => import('@/views/pages/register'),
	},
	{
		path: '/forget-password',
		name: 'forgetPassword',
		meta: {
			navbarStyle: 'two',
			requiresGuest: true,
		},
		component: () => import('@/views/pages/forgetPassword'),
	},
	{
		path: '/reset-password/:token?',
		name: 'resetPassword',
		meta: {
			navbarStyle: 'two',
			requiresGuest: true,
		},
		component: () => import('@/views/pages/resetPassword'),
	},
	{
		path: '/email-verify/:token?',
		name: 'emailVerify',
		meta: {
			navbarStyle: 'two',
			requiresGuest: true,
		},
		component: () => import('@/views/pages/verifyEmail'),
	},
	{
		path: '/phone-verify',
		name: 'phoneVerify',
		meta: {
			navbarStyle: 'two',
			requiresGuest: true,
		},
		component: () => import('@/views/pages/verifyPhone'),
	},
	{
		path: '/terms-conditions',
		name: 'termsConditions',
		meta: {},
		component: () => import('@/views/pages/termsCondition'),
	},
	{
		path: '/privacy-policy',
		name: 'privacyPolicy',
		meta: {},
		component: () => import('@/views/pages/privacyPolicy'),
	},

	{
		path: '/account',
		name: 'account',
		redirect: {
			name: 'view_applications',
		},
		meta: {
			navbarStyle: 'two',
			requiresAuth: true,
		},
		component: () => import('@/views/account/index'),
		children: [
			{
				path: 'applications',
				name: 'account_applications',
				redirect: {
					name: 'view_applications',
				},

				component: () => import('@/views/account/applications/index'),
				children: [
					{
						path: '',
						alias: 'applications',
						name: 'view_applications',
						meta: {
							title: 'طلبات التمويل',
						},
						component: () => import('@/views/account/applications/list'),
					},
					{
						path: 'create',
						name: 'create_applications',
						meta: {
							title: 'طلب تمويل جديد',
						},
						component: () => import('@/views/account/applications/create'),
					},
					{
						path: 'show/:id',
						name: 'show_applications',
						meta: {
							title: 'طلب تمويل',
						},
						component: () => import('@/views/account/applications/show'),
					},
				],
			},
			{
				path: 'personal-data',
				name: 'personalData',
				meta: {
					title: 'حسابي الشخصي',
				},
				component: () => import('@/views/account/personalData'),
			},
		],
	},
];
