import { createWebHistory, createRouter } from 'vue-router';
import Routes from '@/router/routes';

const router = createRouter({
	history: createWebHistory('/'),
	linkExactActiveClass: 'active',
	routes: Routes,
	scrollBehavior() {
		return { top: 0, behavior: 'smooth' };
	},
});

router.beforeResolve((to, from, next) => {
	if (Object.keys(to.query) == 0 && Object.keys(from.query) != 0) {
		to.query = from.query;
		return next({ path: to.path, query: to.query, replace: true });
	}

	return next();
});

router.beforeEach((to, from, next) => {
	const isLoggedIn = localStorage.getItem('token');
	// if (Object.keys(from.query).length > 0) {
	// 	next({ path: to.path, query: { ...from.query }, replace: true });
	// } else {
	// 	next();
	// }

	// to.query = { ...from.query };

	// to.query.ttt ??= 'd';

	if (to.meta.requiresAuth && !isLoggedIn) {
		next({ name: 'login', query: { ...from.query }, replace: true });
	}

	if (to.meta.requiresGuest && isLoggedIn) {
		next({ name: 'account', query: { ...from.query }, replace: true });
	}

	return next();
});

export default router;
